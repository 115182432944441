import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getStudents = createAsyncThunk(
  'getStudents',
  async function (getAccess, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${getAccess}`);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
      credentials: 'include',
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/one_c_users/raw_student_info`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let _token = JSON.parse(result);

        dispatch(getData(_token));
      })
      .catch((error) => {
        console.log('error', error);
      });
  },
);

export const getStudentFoto = createAsyncThunk(
  'getStudentFoto',
  async function (tokenPlusemail, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${tokenPlusemail.access}`);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/images/email/${tokenPlusemail.email}`,
      requestOptions,
    )
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => dispatch(tokenPlusemail.url === 'instructor' ? getInstructorFoto(reader.result) :  getFoto(reader.result));
      })
      .catch((error) => console.log('error', error));
  },
);

export const getMoodleStudents = createAsyncThunk(
  'getMoodleStudents',
  async function (getData, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${getData.getAccess}`);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/personal_accounts/account_info?moodle_user_id=${getData.moodleId}`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => {
        let dataMoodle = JSON.parse(result);
        dispatch(getMoodle(dataMoodle));
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(getErrorStudent('student not found'));
      });
  },
);

export const getPrediction = createAsyncThunk(
  'getPrediction',
  async function (getData, { dispatch }) {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${getData.getAccess}`);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}/ml/prediction/success_failure?courses=${getData.courses}&student_email=${getData.student_email}`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => {
        let dataMoodle = JSON.parse(result);
        let obj = [];
        let newArr = Object.entries(dataMoodle[0]);
        for (const [key, val] of newArr) {
          getData.sortArr.forEach((item) => {
            if (item.idnumber.includes(key)) {
              obj = [
                ...obj,
                {
                  name: item.moodle_course_name,
                  value: val,
                },
              ];
            }
          });
        }
        dispatch(getDataPrediction(obj));
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(getDataPrediction('Too few data to predict'));
      });
  },
);

export const studentsSlice = createSlice({
  name: 'students',
  initialState: {
    data: [],
    moodle: [],
    id: [],
    error: [],
    prediction: [],
    foto: [],
    fotoInstructor: [],
    filterName: '',
    contacts: {},
  },
  reducers: {
    getData(state, action) {
      state.data = action.payload;
    },
    getMoodle(state, action) {
      state.moodle = action.payload;
    },
    getParticularID(state, action) {
      state.id = action.payload;
    },
    getErrorStudent(state, action) {
      state.error = action.payload;
    },
    getDataPrediction(state, action) {
      state.prediction = action.payload;
    },
    getFoto(state, action) {
      state.foto = action.payload;
    },
    getInstructorFoto(state, action) {
      state.fotoInstructor = action.payload;
    },
    getFilterName(state, action) {
      state.filterName = action.payload;
    },
    ContactData(state, action){
      state.contacts = action.payload;
    },
  },
});


export const { getData, getMoodle, getParticularID, getErrorStudent, getDataPrediction, getFoto, getFilterName, ContactData, getInstructorFoto } =
  studentsSlice.actions;
export default studentsSlice.reducer;
